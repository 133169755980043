var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VRow',{staticClass:"answer-poly-wrapper",attrs:{"no-gutters":""}},[_c('VCol',{attrs:{"cols":"12"}},[(_vm.isErrorVisible)?_c('p',{staticClass:"tt-text-body-2 tt-light-red--text mb-4"},[_vm._v(" "+_vm._s(_vm.errorsText.skipped)+" ")]):_vm._e()]),(_vm.isCheckbox)?_c('VRow',{attrs:{"no-gutters":""}},[_c('VCol',{attrs:{"cols":"12"}},_vm._l((_vm.mappedChoices),function(choice,index){return _c('VRow',{key:choice.id,class:[
          index === (_vm.mappedChoices.length - 1) ? 'mb-0' : 'mb-4',
          index ? 'mt-4' : 'mt-0',
          choice.answerClass
        ],attrs:{"no-gutters":""}},[_c('VCol',{attrs:{"cols":"12"}},[_c('TTCheckbox',{staticClass:"answer-input pt-0 mt-0",class:[
              choice.answerClass
            ],attrs:{"input-value":choice.isChecked,"data-test-label":`${choice.id}`,"on-icon":choice.checkboxIcon},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"d-block mb-0"},[_vm._v(" "+_vm._s(choice.title)+" ")]),(choice.comment)?_c('span',{staticClass:"comment d-block mb-0 mt-1"},[_vm._v(" "+_vm._s(choice.comment)+" ")]):_vm._e()]},proxy:true}],null,true)})],1),_c('VCol',{attrs:{"cols":"4"}},[(choice.hasImages)?_c('AnswerImages',{staticClass:"mt-2",attrs:{"is-review":"","image-uuids":choice.polyTypeAnswerPayload.imageUuids}}):_vm._e()],1)],1)}),1)],1):_c('VRow',{attrs:{"no-gutters":""}},[_c('VCol',{attrs:{"cols":"12"}},[_c('VRadioGroup',{staticClass:"mt-0 pa-0",model:{value:(_vm.selectedRadio),callback:function ($$v) {_vm.selectedRadio=$$v},expression:"selectedRadio"}},_vm._l((_vm.mappedChoices),function(choice,index){return _c('VRow',{key:choice.id,class:[
            index === (_vm.mappedChoices.length - 1) ? 'mb-0' : 'mb-4',
            choice.answerClass
          ],attrs:{"no-gutters":""}},[_c('VCol',{attrs:{"cols":"12"}},[_c('TTRadio',{staticClass:"answer-input my-0 pt-0",class:[
                choice.answerClass
              ],attrs:{"value":choice.id,"data-test-label":`${choice.id}`},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"d-block mb-0"},[_vm._v(" "+_vm._s(choice.title)+" ")]),(choice.comment)?_c('span',{staticClass:"comment d-block mb-0 mt-1"},[_vm._v(" "+_vm._s(choice.comment)+" ")]):_vm._e()]},proxy:true}],null,true)}),_c('VCol',{staticClass:"pa-0",attrs:{"cols":"4"}},[(choice.hasImages)?_c('AnswerImages',{staticClass:"mt-2",attrs:{"is-review":"","image-uuids":choice.polyTypeAnswerPayload.imageUuids}}):_vm._e()],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }