
import {
  computed,
  defineComponent, PropType, toRefs,
} from 'vue';

interface ICheckedAnswer {
  title: string;
  isCorrect: boolean | null;
}

export default defineComponent({
  name: 'QuizAnswersQuestionAnswerOrderAnswer',

  props: {
    answer: {
      type: Object as PropType<ICheckedAnswer | null>,
      default: null,
    },
  },

  setup(props) {
    const { answer } = toRefs(props);

    const title = computed(() => answer.value?.title);
    const isCorrect = computed(() => answer.value?.isCorrect === true);
    const isIncorrect = computed(() => answer.value?.isCorrect === false);

    const wrapperProps = computed(() => {
      const dataTestLabel = isCorrect.value
        ? 'right'
        : isIncorrect.value
          ? 'wrong'
          : null;

      return {
        'data-test-label': dataTestLabel,
      };
    });

    return {
      title, isCorrect, isIncorrect, wrapperProps,
    };
  },
});
