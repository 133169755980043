
import {
  defineComponent, PropType, computed, toRefs,
} from 'vue';
import TTChip from '@uikit/components/TTChip/TTChip.vue';
import {
  IQuizQuestionResultOpen, QuizQuestionResultStatus,
} from '@/domains/quiz';
import { IErrorsTextProp } from '@/components/quiz/QuizAnswer/QuizAnswersQuestion/types';

export default defineComponent({
  name: 'QuizAnswersQuestionAnswerOpen',
  components: { TTChip },

  props: {
    answeredChoices: {
      type: Array as PropType<IQuizQuestionResultOpen['answeredChoices']>,
      required: true,
    },

    extendedChoices: {
      type: Array as PropType<IQuizQuestionResultOpen['extendedChoices']>,
      required: true,
    },

    status: {
      type: String as PropType<QuizQuestionResultStatus>,
      required: true,
    },

    errorsText: {
      type: Object as PropType<IErrorsTextProp>,
      required: true,
    },
  },

  setup(props) {
    const { answeredChoices, extendedChoices, status } = toRefs(props);

    const userAnswer = computed(() => answeredChoices.value[0].openTypeAnswerPayload.userAnswer);
    const rightAnswer = computed(() => extendedChoices.value[0].title);
    const isAnswered = computed(() => userAnswer.value !== null);
    const isErrorVisible = computed(() => !isAnswered.value);
    const isCorrect = computed(() => status.value === QuizQuestionResultStatus.CORRECT);
    const answerIconColor = computed(() => (status.value === QuizQuestionResultStatus.CORRECT
      ? 'tt-light-green vibrant' : 'tt-light-red base'));

    return {
      isErrorVisible,
      isAnswered,
      userAnswer,
      rightAnswer,
      answerIconColor,
      isCorrect,
    };
  },
});
