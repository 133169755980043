
import {
  defineComponent, PropType, computed, ref, toRefs,
} from 'vue';

import {
  IQuizQuestionResult,
  IQuizQuestionResultAnswerHash,
  IQuizQuestionResultHash,
  QuizAnswerResultStatus,
  QuizAnswersDisplayType,
  QuizQuestionHashChoiceType,
  QuizQuestionResultStatus,
} from '@/domains/quiz';
import { IErrorsTextProp } from '@/components/quiz/QuizAnswer/QuizAnswersQuestion/types';
import QuizAnswersQuestionAnswerHashPairs from './QuizAnswersQuestionAnswerHashPairs.vue';

export default defineComponent({
  name: 'QuizAnswersQuestionAnswerHash',

  components: {
    QuizAnswersQuestionAnswerHashPairs,
  },

  props: {
    answeredChoices: {
      type: Array as PropType<IQuizQuestionResultHash['answeredChoices']>,
      required: true,
    },

    extendedChoices: {
      type: Array as PropType<IQuizQuestionResultHash['extendedChoices']>,
      required: true,
    },

    displayType: {
      type: String as PropType<QuizAnswersDisplayType>,
      required: true,
    },

    payload: {
      type: Object as PropType<IQuizQuestionResult['hashTypeQuestionPayload']>,
      required: true,
    },

    status: {
      type: String as PropType<QuizQuestionResultStatus>,
      required: true,
    },

    errorsText: {
      type: Object as PropType<IErrorsTextProp>,
      required: true,
    },
  },

  setup(props) {
    const {
      answeredChoices, extendedChoices, status, payload,
    } = toRefs(props);

    const isErrorVisible = computed(() => status.value === QuizQuestionResultStatus.SKIPPED
      || answeredChoices.value.length !== extendedChoices.value.length);

    const hasAnyAnswer = computed(() => answeredChoices.value.length > 0);

    interface IUserAnswerPair {
    isCorrect: boolean;
    left: IQuizQuestionResultAnswerHash;
    right: IQuizQuestionResultAnswerHash;
}
    const userAnswers = answeredChoices.value.map((choice) => {
      if (choice.hashTypeAnswerPayload.type === QuizQuestionHashChoiceType.KEY) {
        const left = choice;
        const right = answeredChoices.value.find(({ id }) => id === choice.hashTypeAnswerPayload.relatedIn)!;
        const isCorrect = choice.correctStatus === QuizAnswerResultStatus.ISCORRECT;

        return {
          isCorrect,
          left,
          right,
        };
      }
      return null;
    }).filter((answer) => answer) as IUserAnswerPair[];

    const correctAnswers = payload.value?.rightRelation.map((relation) => {
      const [keyId, valueId] = relation;
      const left = extendedChoices.value.find(({ id }) => id === keyId)!;
      const right = extendedChoices.value.find(({ id }) => id === valueId)!;
      const isCorrect = true;

      return {
        isCorrect,
        left,
        right,
      };
    }).filter((answer) => {
      const userAnswer = userAnswers.find((a) => a.left?.id === answer.left?.id);

      return !userAnswer?.isCorrect;
    }) ?? [];

    const isCorrect = computed(() => status.value === QuizQuestionResultStatus.CORRECT);

    // Кнопка "Показать правильный ответ"
    const isCorrectAnswerVisible = ref(!hasAnyAnswer.value);

    const showCorrectAnswer = () => {
      isCorrectAnswerVisible.value = true;
    };

    const textOfError = computed(() => {
      if (status.value === QuizQuestionResultStatus.SKIPPED || answeredChoices.value.length === 0) {
        return props.errorsText.skipped;
      }
      return props.errorsText.partly;
    });

    return {
      isErrorVisible,
      hasAnyAnswer,
      userAnswers,
      correctAnswers,
      isCorrectAnswerVisible,
      showCorrectAnswer,
      isCorrect,
      textOfError,
    };
  },
});
