
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';

import {
  IQuizQuestionResultOrder,
  QuizAnswerResultStatus,
  QuizQuestionResultStatus,
} from '@/domains/quiz';
import { IErrorsTextProp } from '@/components/quiz/QuizAnswer/QuizAnswersQuestion/types';

import QuizAnswersQuestionAnswerOrderIndex from './QuizAnswersQuestionAnswerOrderIndex.vue';
import QuizAnswersQuestionAnswerOrderAnswer from './QuizAnswersQuestionAnswerOrderAnswer.vue';
import QuizAnswersQuestionAnswerOrderChoice from './QuizAnswersQuestionAnswerOrderChoice.vue';

interface ICheckedAnswer {
  title: string;
  isCorrect: boolean | null;
}

export default defineComponent({
  name: 'QuizAnswersQuestionAnswerOrder',

  components: {
    QuizAnswersQuestionAnswerOrderIndex,
    QuizAnswersQuestionAnswerOrderAnswer,
    QuizAnswersQuestionAnswerOrderChoice,

  },

  props: {
    answeredChoices: {
      type: Array as PropType<IQuizQuestionResultOrder['answeredChoices']>,
      required: true,
    },

    extendedChoices: {
      type: Array as PropType<IQuizQuestionResultOrder['extendedChoices']>,
      required: true,
    },

    status: {
      type: String as PropType<QuizQuestionResultStatus>,
      required: true,
    },

    errorsText: {
      type: Object as PropType<IErrorsTextProp>,
      required: true,
    },
  },

  setup(props) {
    const { answeredChoices, extendedChoices, status } = toRefs(props);

    const checkedAnswer = computed<(ICheckedAnswer | null)[]>(() => {
      const res: (ICheckedAnswer | null)[] = Array(extendedChoices.value.length).fill(null);

      extendedChoices.value.forEach((choice) => {
        const answeredChoice = answeredChoices.value.find(({ id }) => id === choice.id);

        if (answeredChoice) {
          res.splice(answeredChoice.orderTypeAnswerPayload.order - 1, 1, {
            ...choice,
            isCorrect: answeredChoice.correctStatus === QuizAnswerResultStatus.ISCORRECT,
          });
        }
      });
      return res;
    });

    const hasAnyAnswer = computed(() => answeredChoices.value.length > 0);

    const textOfError = computed(() => {
      if (answeredChoices.value.length) {
        return props.errorsText.partly;
      }
      return props.errorsText.skipped;
    });

    const isErrorVisible = computed(() => status.value === QuizQuestionResultStatus.SKIPPED
      || answeredChoices.value.length !== extendedChoices.value.length);

    return {
      checkedAnswer, hasAnyAnswer, isErrorVisible, textOfError,
    };
  },
});
