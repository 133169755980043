
import {
  defineComponent, PropType, computed, toRefs,
} from 'vue';

import {
  IQuizQuestionResult,
  IQuizQuestionResultPoly,
  QuizAnswerResultStatus,
  QuizQuestionPolyTypeMode,
  QuizQuestionResultStatus,
} from '@/domains/quiz';
import { IErrorsTextProp } from '@/components/quiz/QuizAnswer/QuizAnswersQuestion/types';
import AnswerImages from '@/components/ui/AnswerImages';

enum ChoiceClasses {
  CORRECT = 'correct',
  WRONG = 'wrong'
}

export default defineComponent({
  name: 'QuizAnswersQuestionAnswerPoly',

  components: {
    AnswerImages,
  },

  props: {
    answeredChoices: {
      type: Array as PropType<IQuizQuestionResultPoly['answeredChoices']>,
      required: true,
    },

    extendedChoices: {
      type: Array as PropType<IQuizQuestionResultPoly['extendedChoices']>,
      required: true,
    },

    payload: {
      type: Object as PropType<IQuizQuestionResult['polyTypeQuestionPayload']>,
      required: true,
    },

    status: {
      type: String as PropType<QuizQuestionResultStatus>,
      required: true,
    },

    errorsText: {
      type: Object as PropType<IErrorsTextProp>,
      required: true,
    },
  },

  setup(props) {
    const {
      extendedChoices, answeredChoices, payload, status,
    } = toRefs(props);
    const hasAnyAnswer = answeredChoices.value.some(({ polyTypeAnswerPayload }) => polyTypeAnswerPayload.checked);
    const isErrorVisible = computed(() => status.value === QuizQuestionResultStatus.SKIPPED || !hasAnyAnswer);

    const isCheckbox = computed(() => payload.value?.type === QuizQuestionPolyTypeMode.POLY);

    const selectedRadio = computed(() => (isCheckbox.value ? undefined
      : answeredChoices.value.find(({ polyTypeAnswerPayload }) => polyTypeAnswerPayload.checked)?.id));

    const mappedChoices = computed(() => answeredChoices.value.map((choice) => {
      const extendedChoice = extendedChoices.value.find(({ id }) => id === choice.id);
      const isChecked = choice.polyTypeAnswerPayload.checked;

      let answerClass = null;
      if (extendedChoice!.polyTypeChoicePayload.right) answerClass = ChoiceClasses.CORRECT;
      if (!extendedChoice!.polyTypeChoicePayload.right && choice.polyTypeAnswerPayload.checked) {
        answerClass = ChoiceClasses.WRONG;
      }

      // выберет иконку чекбокса
      const checkboxIcon = (choice.correctStatus === QuizAnswerResultStatus.ISCORRECT)
        || extendedChoice?.polyTypeChoicePayload.right
        ? '$checkSolid' : '$timesSolid';

      return {
        ...choice,
        isChecked,
        checkboxIcon,
        answerClass,
        hasImages: choice.polyTypeAnswerPayload.imageUuids.length,
      };
    }));

    return {
      isErrorVisible,
      isCheckbox,
      selectedRadio,
      mappedChoices,
    };
  },

});
