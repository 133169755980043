
import {
  defineComponent, toRefs, computed,
} from 'vue';
import ProgressBar from '@/components/ui/ProgressBar/ProgressBar.vue';

const PROGRESS_BAR_HEIGHT = 8;

export default defineComponent({
  name: 'QuizAnswersProgressPlate',

  components: { ProgressBar },

  props: {
    correctAnswersCount: {
      type: Number,
      required: true,
    },

    questionsCount: {
      type: Number,
      required: true,
    },

    /**
     * 0..1
     */
    testThreshold: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { correctAnswersCount, questionsCount, testThreshold } = toRefs(props);

    const progress = computed(() => correctAnswersCount.value / (
      (questionsCount.value > 0) ? questionsCount.value : 1));
    const testPassed = computed(() => progress.value >= testThreshold.value);
    const progressColor = computed(() => (testPassed.value ? 'tt-light-green vibrant' : 'tt-light-blue base'));
    const progressPercent = computed(() => Math.floor(progress.value * 100));
    const ghostProgressPercent = computed(() => (testPassed.value ? 0 : testThreshold.value * 100));

    return {
      PROGRESS_BAR_HEIGHT,
      progress,
      progressPercent,
      progressColor,
      ghostProgressPercent,
    };
  },
});
