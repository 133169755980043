var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._b({attrs:{"data-test":"answer"}},'div',_vm.wrapperProps,false),[_c('div',{staticClass:"tt-flex align-center tt-flex-gap-6 tt-text-body-2"},[_c('div',{staticClass:"tt-light-mono-4 rounded pa-3 col-6 pair pair--left",class:{
        'pair--correct' : _vm.isCorrect,
        'pair--incorrect' : !_vm.isCorrect
      },attrs:{"data-test":"left","data-test-label":_vm.right.id}},[_vm._v(" "+_vm._s(_vm.left.title)+" ")]),_c('div',{staticClass:"tt-light-mono-4 rounded pa-3 col-6 pair pair--right",class:{
        'pair--correct' : _vm.isCorrect,
        'pair--incorrect' : !_vm.isCorrect
      },attrs:{"data-test":"right","data-test-label":_vm.right.id}},[_vm._v(" "+_vm._s(_vm.right.title)+" ")])]),((!_vm.isCorrect || _vm.isErrorVisible) && (_vm.left.comment || _vm.right.comment) && _vm.isUserAnswerCorrect)?_c('p',{staticClass:"mt-1 mb-0 tt-text-body-2",class:{
      'tt-light-mono-64--text' : _vm.isCorrect,
      'tt-light-red--text pale' : !_vm.isCorrect
    },attrs:{"data-test":"comment"}},[_vm._v(" "+_vm._s(_vm.left.comment || _vm.right.comment)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }