
import Vue, { PropType } from 'vue';
import { QuizAnswersDisplayType } from '@/domains/quiz';

export default Vue.extend({
  name: 'QuizAnswersQuestionAnswerUnknown',

  props: {
    choices: {
      type: Array,
      required: true,
    },

    answer: {
      type: Object,
      required: true,
    },

    displayType: {
      type: String as PropType<QuizAnswersDisplayType>,
      required: true,
    },
  },
});
