import { computed, ref, Ref } from 'vue';
import { useDi } from '@/plugins/di';

import { ITestInfoGetResponseData as IQuizResult } from '@/services/api/lxp-quiz-test/types';
import * as LxpQuizTestService from '@/services/api/lxp-quiz-test';
import { IQuizQuestionResult, QuizAnswersDisplayType } from '../types';

export const useQuizDetailedResult = (
  { quiz, playerSessionId }: { quiz: Ref<IQuizResult>, playerSessionId: Ref<number>},
) => {
  const di = useDi();

  const correctAnswers = computed(() => quiz.value.results?.correctAnswers ?? 0);
  const questionsCount = computed(() => (quiz.value.questionsCount >= 0 ? quiz.value.questionsCount : 0));
  const testThreshold = computed(() => (
    (quiz.value.results?.testThreshold
      && quiz.value.results?.testThreshold >= 0
      && quiz.value.results?.testThreshold <= 1)
      ? quiz.value.results?.testThreshold
      : 0));

  const answersDisplayType = computed(() => quiz.value.results?.answersDisplayType ?? QuizAnswersDisplayType.NONE);

  const questions = ref<IQuizQuestionResult[]>([]);
  const fetchQuizResults = async () => {
    const params = {
      playerSessionId: playerSessionId.value,
    };

    try {
      const { questions: response } = await LxpQuizTestService.testSessionResults({ params });

      questions.value = response;
    } catch (e: any) {
      console.error(e);
      di.notify.error({ content: e.message });
    }
  };

  return {
    questions,
    correctAnswers,
    questionsCount,
    testThreshold,
    answersDisplayType,
    fetchQuizResults,
  };
};
