
import {
  defineComponent, PropType,
} from 'vue';
import { IQuizExtendedChoiceOrder } from '@/domains/quiz';

export default defineComponent({
  name: 'QuizAnswersQuestionAnswerOrderChoice',

  props: {
    choice: {
      type: Object as PropType<IQuizExtendedChoiceOrder>,
      required: true,
    },
  },
});
