
import {
  defineComponent, PropType, computed, toRefs,
} from 'vue';
import { IQuizQuestionResult, QuizAnswersDisplayType, normalizeQuestionType } from '@/domains/quiz';
import QuizAnswersQuestionComment from './QuizAnswersQuestionComment.vue';
import QuizAnswersQuestionAnswer from './QuizAnswersQuestionAnswer.vue';
import QuestionImages from '@/components/ui/QuestionImages';

export default defineComponent({
  name: 'QuizAnswersQuestion',

  components: {
    QuizAnswersQuestionComment,
    QuizAnswersQuestionAnswer,
    QuestionImages,
  },

  props: {
    question: {
      type: Object as PropType<IQuizQuestionResult>,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    displayType: {
      type: String as PropType<QuizAnswersDisplayType>,
      required: true,
    },
  },

  setup(props) {
    const { question } = toRefs(props);

    const headerProps = computed(() => ({
      'data-test': 'title',
    }));

    const containerProps = computed(() => ({
      'data-test': `${normalizeQuestionType(question.value.type)}-question-wrapper`,
    }));

    const hasImages = computed(() => question.value.imageUuids.length > 0);
    const imagesCols = computed(() => (question.value.imageUuids.length >= 3 ? '6' : '8'));

    return {
      containerProps, headerProps, hasImages, imagesCols,
    };
  },
});
