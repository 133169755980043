
import {
  computed, defineComponent,
} from 'vue';

export default defineComponent({
  name: 'ProgressBar',

  props: {
    progress: {
      type: Number,
      required: true,
      validator: (progress: number) => {
        return progress >= 0 && progress <= 100;
      },
    },
    color: {
      type: String,
      default: 'tt-light-blue vibrant',
    },
    height: {
      type: Number,
      default: 4,
    },
    backgroundColor: {
      type: String,
      default: 'tt-light-mono-4',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Покажет круг прогресса, даже если прогресс 0
     */
    showZeroPoint: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const zeroPointClass = computed(() => (props.showZeroPoint ? `zero-point-${props.height}` : ''));

    return {
      zeroPointClass,
    };
  },

});
