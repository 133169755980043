
import {
  defineComponent, PropType,
} from 'vue';
import {
  IQuizQuestionResultAnswerHash,
} from '@/domains/quiz';

import QuizAnswersQuestionAnswerHashPair from './QuizAnswersQuestionAnswerHashPair.vue';

export default defineComponent({
  name: 'QuizAnswersQuestionAnswerHashPairs',

  components: {
    QuizAnswersQuestionAnswerHashPair,
  },

  props: {
    pairs: {
      type: Array as PropType<{
        isCorrect: boolean;
        left: IQuizQuestionResultAnswerHash;
        right: IQuizQuestionResultAnswerHash;
      }[]>,
      required: true,
    },
  },
});
