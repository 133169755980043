
import { defineComponent, PropType } from 'vue';
import {
  IQuizQuestionResult, QuizAnswersDisplayType, QuizQuestionResultStatus, QuizQuestionType,
} from '@/domains/quiz';
import { IErrorsTextProp } from './types';
import QuizAnswersQuestionAnswerPoly from './QuizAnswersQuestionAnswerPoly';
import QuizAnswersQuestionAnswerHash from './QuizAnswersQuestionAnswerHash.vue';
import QuizAnswersQuestionAnswerOrder from './QuizAnswersQuestionAnswerOrder.vue';
import QuizAnswersQuestionAnswerOpen from './QuizAnswersQuestionAnswerOpen.vue';
import QuizAnswersQuestionAnswerUnknown from './QuizAnswersQuestionAnswerUnknown.vue';

export default defineComponent({
  name: 'QuizAnswersQuestionAnswer',

  inject: ['Names'],

  props: {
    type: {
      type: String as PropType<QuizQuestionType>,
      required: true,
    },

    answeredChoices: {
      type: Array as PropType<IQuizQuestionResult['answeredChoices']>,
      required: true,
    },

    extendedChoices: {
      type: Array as PropType<IQuizQuestionResult['extendedChoices']>,
      required: true,
    },

    displayType: {
      type: String as PropType<QuizAnswersDisplayType>,
      required: true,
    },

    status: {
      type: String as PropType<QuizQuestionResultStatus>,
      required: true,
    },

    polyTypeQuestionPayload: {
      type: Object as PropType<IQuizQuestionResult['polyTypeQuestionPayload']>,
      default: () => null,
    },

    hashTypeQuestionPayload: {
      type: Object as PropType<IQuizQuestionResult['hashTypeQuestionPayload']>,
      default: () => null,
    },
  },

  computed: {
    component() {
      switch (this.type) {
        case QuizQuestionType.POLY:
          return QuizAnswersQuestionAnswerPoly;
        case QuizQuestionType.HASH:
          return QuizAnswersQuestionAnswerHash;
        case QuizQuestionType.ORDER:
          return QuizAnswersQuestionAnswerOrder;
        case QuizQuestionType.OPEN:
          return QuizAnswersQuestionAnswerOpen;
        default:
          return QuizAnswersQuestionAnswerUnknown;
      }
    },

    payload(): unknown {
      switch (this.type) {
        case QuizQuestionType.POLY:
          return this.polyTypeQuestionPayload;
        case QuizQuestionType.HASH:
          return this.hashTypeQuestionPayload;
        default:
          return null;
      }
    },

    isOwnResult(): boolean {
      return !(this.$route.name === this.Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ_DETAILS);
    },

    errorsText(): IErrorsTextProp {
      return this.isOwnResult
        ? ({
          skipped: this.$t('QuizAnswersQuestionAnswerHash.error.own'),
          partly: this.$t('QuizAnswersQuestionAnswerHash.partlyAnsweredError.own'),
        })
        : ({
          skipped: this.$t('QuizAnswersQuestionAnswerHash.error.employee'),
          partly: this.$t('QuizAnswersQuestionAnswerHash.partlyAnsweredError.employee'),
        });
    },
  },
});
