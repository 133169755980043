
import { defineComponent } from 'vue';

import BulbDarkImage from '@/assets/img/bulb-dark.png';

export default defineComponent({
  name: 'QuizAnswersQuestionComment',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    styles() {
      return {
        backgroundImage: `url(${BulbDarkImage})`,
        backgroundOrigin: 'content-box',
        backgroundSize: '20px 20px',
      };
    },
  },
});
