
import {
  computed,
  defineComponent, PropType, toRefs,
} from 'vue';
import {
  IQuizQuestionResultAnswerHash,
} from '@/domains/quiz';

export default defineComponent({
  name: 'QuizAnswersQuestionAnswerHashPair',

  props: {
    left: {
      type: Object as PropType<IQuizQuestionResultAnswerHash>,
      required: true,
    },

    right: {
      type: Object as PropType<IQuizQuestionResultAnswerHash>,
      required: true,
    },

    isCorrect: {
      type: Boolean,
      required: true,
    },

    isErrorVisible: {
      type: Boolean,
      default: false,
    },

    isUserAnswerCorrect: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { isCorrect } = toRefs(props);

    const wrapperProps = computed(() => ({
      'data-test-label': isCorrect.value ? 'right' : 'wrong',
    }));

    return {
      wrapperProps,
    };
  },
});
